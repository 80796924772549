/*********************  Default-CSS  *********************/

/* SCROLL ================================== */
::-webkit-scrollbar-track
{
    border: 0.3em solid #ffffff;
    border-radius: 1em;
    box-shadow: 0 0 0 13px #ffffff;
    background-color: #333333;
}

::-webkit-scrollbar
{
    border-radius: 1em;
    width: 1.2em;
    background-color: #333333;
}

::-webkit-scrollbar-thumb
{
    border-radius: 1em;
    border: 0.2em solid #333333;
    background-color: #ffffff;
}


:root {
    scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth;
    -moz-animationscroll-behavior: smooth;
}

.hide-scrollbar ::-webkit-scrollbar {
        display: none;
}

.no-scroll {
    overflow: hidden;
    height: 100%;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

body {
    margin: 0;
    color: #000;
    background-color: #fff;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

div {
    flex-direction: row;
}

a,
span,
a:hover,
a:active,
button {
    text-decoration: none;
}

a,
div a:hover,
div a:active,
div a:focus,
button {
    text-decoration: none;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}

p {
    margin: 0;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
    margin: 0;
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
}

.row {
    margin: 0 -1em;
}

.container {
    padding: 0 1em;
}

.col, [class*=col-] {
    padding: 0 1em;
}

.cm_overflow {
    overflow: hidden;
    touch-action: none;
}

/*********************  Default-CSS  End *********************/

/********************  NavBar Css Start  *********************/

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    padding: 0.4em 0 0.4em;
}

header.fixed {
    padding: 0.5em 0;
}

.in_header_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 1em;
}

.logo_hold {
    position: relative;
    z-index: 110;
    max-width: 3em;
}

.logo_hold a {
    width: 6em;
    display: inline-block;
}

.menu_block {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(76 76 76);
    clip-path: circle(0em at 100% 0em);
    -webkit-clip-path: circle(0em at 100% 0em);
    -webkit-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
    transition: 0.5s ease all;
    z-index: 100;
    padding: 8em 0 2em;
    display: flex;
    align-items: center;
    flex-flow: column;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.menu_block ul {
    display: flex;
    align-items: center;
    line-height: 1;
    flex-direction: column;
}

@media screen and (min-width: 992px) {
    .menu_block ul {
        line-height: 0;
        flex-direction: column;
    }
}

.menu_block ul li {
    padding-bottom: 0.5em;
    margin-right: 0;
    text-align: left;
    width: 100%;
    min-width: auto;
    transform: translateX(100vw);
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu_block ul li:nth-child(1) {
    transition-delay: 0.10s;
}

.menu_block ul li:nth-child(3) {
    transition-delay: 0.15s;
}

.menu_block ul li:nth-child(5) {
    transition-delay: 0.25s;
}

.menu_block ul li:nth-child(6) {
    transition-delay: 0.30s;
}

.menu_block ul li:nth-child(7) {
    transition-delay: 0.35s;
}

.menu_block ul li.active::before {
    display: none;
}

.menu_block ul li .header_join_btn a {
    width: 8em;
    height: 2em;
    font-size: 2em;
    line-height: 2;
    color: #fff;
    padding: 0.7em 2em;
}

.menu_block .menu_list > ul li {
    width: 100%;
    height: unset;
    margin-bottom: 1.3em;
    padding-left: 0;
    margin-right: 0;
}

@media screen and (min-width: 992px) {
    .menu_block .menu_list > ul li{
        margin-bottom: 3em;
    }
}

.menu_block ul li a {
    font-weight: 600;
    color: #fff;
    font-size: 1.8em;
}

.menu_block ul li a:hover {
    color: #ff6935;
}

.menu_block ul span {
    color: #fff;
    font-size: 1.2em;
    font-family: "Poppins SemiBold", serif;
    margin: 0 0.8em 0.6em 0.8em;
}

.menu_block .social_mobile_menu {
    padding-left: 1em;
    margin-top: 0.8em;
    padding-right: 1em;
}

.menu_block .social_mobile_menu ul {
    display: flex;
    flex-flow: row;
    margin: 0 auto;
}

.menu_block .social_mobile_menu ul li {
    padding-bottom: 0;
}

.menu_block .social_mobile_menu ul li + li {
    margin-top: 0;
}

.menu_block .social_list {
    width: 2em;
}

.menu_block ul li:not(:last-child) {
    margin-right: 10px;
}

.menu_block ul li.active a::after,
.menu_block ul li:hover a::after {
    width: 61%;
}

.menu_block ul span {
    display: none;
}

.menu_toggle_btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: auto;
    position: relative;
    z-index: 102;
}

.menu_list {
    width: 100%;
    position: relative;
    z-index: 99;
}

/********************  Mobile Menu Css Start *********************/

.menu_toggle_btn span:nth-child(1) {
    margin-top: 0.5em;
}

.menu_toggle_btn span {
    width: 1em;
    height: 0.2em;
    margin-bottom: 0.5em;
    background-color: #fff;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.open_menu .menu_block {
    clip-path: circle(100%);
    -webkit-clip-path: circle(100%);
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
    text-align: center;
}

.open_menu .menu_block .menu_list > ul li {
    transform: translateX(0);
}

.menu_toggle_btn span:nth-child(1) {
    transform: rotate(39deg) translateY(3px) translateX(5px);
}

.open_menu .menu_toggle_btn span {
    background-color: #fff;
}

.open_menu .menu_toggle_btn span:nth-child(1) {
    transform: rotate(45deg) translateY(0px) translateX(3px);
}

.open_menu .menu_toggle_btn span:nth-child(2) {
    opacity: 0;
}

.open_menu .menu_toggle_btn span:nth-child(3) {
    transform: rotate(-47deg) translateY(-7px) translateX(10px);
}

/********************  Mobile Menu Css Close *********************/

/********************  NavBar Css End  *********************/

/********************  ScrollingNavBar Css Start  *********************/
.Header2_block {
    padding: 0.8em 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background: rgb(76 76 76 / 35%) linear-gradient(180deg, rgb(51, 51, 51, 0.1), rgba(42, 42, 42, 0.3));
    backdrop-filter: blur(2px);
    box-shadow: inset -1px 1px 1px rgba(255, 255, 255, 0.2), inset 0px 0px 35px rgba(0, 0, 0, 0.04);
    /* display: none; */
}

.Header2_block .Header2_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 97%;
    margin: 0 auto;
}

.Header_button a {
    font-size: 0.9em;
    color: #fff;
    font-weight: 600;
    border-radius: 1em;
    background-image: url(./assets/images/header.png);
    background-repeat: no-repeat;
    padding: 0.2em 2em;
}

.header_block .sticky {
    top: -120px;
    transition: all 1s ease;
}

.active_header_1 .sticky {
    z-index: 999;
    top: 0;
    transition: all 1s ease;
    background-size: cover;
    background: rgb(76 76 76 / 35%) linear-gradient(180deg, rgb(51, 51, 51, 1), rgba(42, 42, 42, 1));
}

/********************  ScrollingNavBar Css End  *********************/


/********************  hero_sec Css Start  *********************/
.front_img {
    width: 100%;
    height: 100vh;
    z-index: 9999;
    position: fixed;
    background-color: rgba(51, 51, 51, 0.99);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.front_img_active {
    opacity: 0;
}

@keyframes fade_in_out {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fade-in {
    -moz-animation: fade_out_in 1.2s;
    -webkit-animation: fade_out_in 1.2s;
    animation: fade_out_in 1.2s;
}

@keyframes fade_out_in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes front_img {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.6);
    }
}

.hero_sec {
    background-color: transparent;
    height: 100vh;
    width: 100%;
    position: relative;
    padding-top: 3em;
}

.hero_text_img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}

.hero_text_img img {
    width: 98%;
}

.learn_more_hero {
    position: absolute;
    bottom: 6em;
    left: 1em;
}

.hero_img_block {
    z-index: -1;
    position: absolute;
    pointer-events: none;
    bottom: 20em;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 130%;
}

/********************  hero_sec Css End  *********************/

/********************  Section1 Css Start  *********************/
.Section1 {
    overflow: hidden;
    z-index: 98;
    position: relative;
}

.Section1::before {
    content: '';
    background-color: #333333;
    width: 100%;
    height: 39%;
    position: absolute;
    left: 0;
    top: 0;
}

.dark_orange {
    color: #ff704d;
}

.medium_orange {
    color: #fe8c70;
}

.web_paragraph {
    font-size: 2em;
    font-weight: bold;
    color: #fff;
    line-height: 1.33;
}

.much_more_sec_text .web_paragraph:last-child {
    margin-top: 3em;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Section1 .much_more_sec_text > p:first-child {
    text-align: center;
}

.much_more_sec_img {
    width: 90%;
}

/********************  Section1 Css End  *********************/

/********************  OurSystemSec Css Start  *********************/

.our_system_bottom_text h2,
.our_system_bottom_text p {
    background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(90deg, #ffa873, #ff5a23, #ff5117);
}

.our_system_bottom_text h2 {
    font-size: 4em;
    color: #ff6935;
    font-family: Quantify, 'Poppins', serif;
    text-align: center;
}

.our_system_bottom_text p {
    font-size: 2em;
    color: rgb(255, 112, 49);
    font-weight: bold;
    text-align: center;
}

.our_system_bottom_text {
    margin-top: 3em;
}

/********************  OurSystemSec Css End  *********************/

/********************  our_passion_sec Css Start  *********************/
.our_passion_sec {
    padding-top: 3em;
}

.our_passion_block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: unset;
}

.our_passion_block .our_passion_row {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    margin-bottom: 2em;
}

.our_passion_row > div {
    width: 100%;
}

.our_passion_position {
    position: relative;
    max-width: 30em;
}

.our_passion_img img {
    max-width: 100%;
}

.our_passion_img p {
    color: #fff;
    font-weight: bold;
    line-height: 1.1;
    position: absolute;
    left: 44%;
    top: 55%;
    transform: translate(-50%, -50%);
    font-size: 1.8em;
}

.our_passion_text {
    margin-top: 1.3em;
    margin-left: 1em;
}

.our_passion_row .our_passion_text h2 {
    font-size: 1.8em;
    color: rgb(51, 51, 51);
    font-weight: bold;
}

.passion_card_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.passion_col {
    margin-bottom: 2em;
    padding: 0 1em;
    width: 50%;
}

.passion_card_block {
    text-align: center;
}

.passion_card_block .passion_card_text h3 {
    font-size: 1.4em;
    color: rgb(51, 51, 51);
    font-weight: bold;
    max-width: 6em;
}

.passion_card_block .passion_card_text p {
    font-size: 1.2em;
    color: rgb(51, 51, 51);
    max-width: 10em;
    margin: auto;
}

/********************  our_passion_sec Css End  *********************/

/********************  from_inside_sec Css Start  *********************/

.from_inside_img {
    display: flex;
    justify-content: flex-end;
}

.from_inside_img img {
    width: 100%;
}

.our_passion_sec {
    padding-top: 3em;
    background: #fff;
    position: relative;
    z-index: 10;
}

.Section1 {
    overflow: hidden;
    z-index: 98;
    position: relative;
    background: #fff;
}

.section_sapch_block {
    padding: 29% 0 0 0 !important;
}

.web_paragraph_2 {
    font-size: 2em;
    margin-bottom: 3em;
    font-weight: bold;
    color: #fff;
    line-height: 1.33;
    text-align: justify;
    text-justify:inter-word;
    letter-spacing: -0.04em;
    hyphens: auto;
}

/********************  from_inside_sec Css End  *********************/

/********************  Section4 Css Start  *********************/
.sam_section_main .web_paragraph_2 {
    color: #333333;
    margin-bottom: 15px;
    max-width: 100%;
}

.sam_section_main.sam_section_down {
    padding: 4em 0 0 0;
}

.sam_section_block {
    display: flex;
    flex-direction: column;
}

.sam_section_row {
    display: flex;
    margin: 0 -1em;
}

.sam_section_row > div {
    width: 90%;
    padding: 0 1em;
}

.sam_section_down .sam_section_block {
    justify-content: flex-end;
}

.sam_section_down .sam_section_row {
    align-items: center;
}

.sam_section_down .sam_section_row div:first-child {
    z-index: 11;
}

/********************  Section4 Css End  *********************/

/********************  InstagramPreFooter Css Start  *********************/
.instagram_sec {
    background-image: url(./assets/images/instagram_block_bg.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    padding: 4em 0 8em 0;
}

.instagram_block_heading h2 {
    color: rgb(255, 112, 49);
    font-family: Quantify, 'Poppins', serif;
    line-height: 0.9;
    background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(40deg, rgb(255 172 100), rgb(240 54 17), rgb(238, 82, 15));
    max-width: 100%;
    font-size: 3.4em;
}

.instagram_block_heading p {
    font-size: 2em;
    font-weight: bold;
    line-height: 1;
    color: #ff7031;
    background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(90deg, #ffac75, rgb(250, 79, 6), rgb(238, 82, 15));
    max-width: 100%;
    margin-bottom: 1em;
}

.article-heading {
    color: #333;
    font-family: Poppins, 'Poppins', serif;
    line-height: 0.9;
    max-width: 100%;
    font-size: 3.4em;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    word-wrap: break-word;
}


.image-wrapper {
    order: 1 !important;
    width: 100%;
    height: auto;
    justify-content: center !important;
}

.image-container {
    width: 100% !important;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.instagram_button {
    display: flex;
    align-items: center;
}

.Instagram_btn a {
    background-image: url(./assets/images/instagram_sec_button.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 1.5em;
    color: #fff;
    font-weight: bold;
    z-index: 2;
}

.instagram_button img {
    max-width: 70%;
}

/********************  InstagramPreFooter Css End  *********************/

/********************  LandingFooter Css Start  *********************/
.footer_sec {
    background-image: url(./assets/images/footer_block_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: -1px;
    width: 100%;
    position: relative;
}

.footer_sec_block .footer_logo {
    padding: 5em 0 8em 0;
    text-align: center;
}

.footer_text_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer_main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1.5em;
}

.footer_icon ul {
    display: flex;
    align-items: center;
}

.footer_icon ul li {
    margin-left: 1em;
}

.footer_icon ul li a {
    display: inline-block;
    width: 3em;
    height: 3em;
}

.footer_icon ul li:first-child a {
    width: 2em;
    height: 2em;
}

.footer_icon ul li a svg path {
    fill: #777777;
    transition: all 0.4s ease;
}

.footer_icon ul li a:hover svg path {
    fill: #d35444;
}

.footer_copyright p {
    font-size: 1em;
    color: #fff;
    font-weight: bold;
    line-height: 1.3;
}

.footer_copyright p a {
    color: #fff;
    transition: all 0.4s ease;
}

.footer_copyright p a:hover {
    color: #565656;
}

img {
    pointer-events: none;
}

.blackbox {
    background: rgb(76 76 76 / 35%) linear-gradient(180deg, rgb(51, 51, 51, 1), rgba(42, 42, 42, 1));
    backdrop-filter: blur(2px);
}

.crystal {
    background: rgb(76 76 76 / 35%) linear-gradient(180deg, rgb(51, 51, 51, 0.1), rgba(42, 42, 42, 0.3));
    backdrop-filter: blur(2px);
    box-shadow: inset -1px 1px 1px rgba(255, 255, 255, 0.2), inset 0 0 35px rgba(0, 0, 0, 0.04);
}

.crystal-variant {
    background: rgb(76 76 76 / 35%) linear-gradient(180deg, rgb(51, 51, 51, 0.1), rgba(42, 42, 42, 0.3));
    backdrop-filter: blur(2px);
    box-shadow: inset -1px 1px 1px rgba(255, 255, 255, 0.2), inset 0 0 35px rgba(0, 0, 0, 0.04);
    transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.crystal-variant-2 {
    background: rgb(42 42 42 / 65%) linear-gradient(180deg, rgb(42, 42, 42, 0.1), rgba(42, 42, 42, 0.3));
    backdrop-filter: blur(2px);
    box-shadow: inset -1px 1px 1px rgba(255, 255, 255, 0.2), inset 0 0 35px rgba(0, 0, 0, 0.04);
    transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.crystal-variant-3 {
    background: rgb(42 42 42 / 85%) linear-gradient(180deg, rgb(42, 42, 42, 0.1), rgba(42, 42, 42, 0.3));
    backdrop-filter: blur(2px);
    box-shadow: inset -1px 1px 1px rgba(255, 255, 255, 0.2), inset 0 0 35px rgba(0, 0, 0, 0.04);
    transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.crystal-variant:hover {
    margin-left: 1em;
    transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.crystal-menu {
    background: rgb(76 76 76 / 35%) linear-gradient(180deg, rgb(51, 51, 51, 0.7), rgba(42, 42, 42, 0.9));
    backdrop-filter: blur(2px);
    box-shadow: inset -1px 1px 1px rgba(255, 255, 255, 0.2), inset 0 0 35px rgba(0, 0, 0, 0.04);
}

.content-links {
    font-weight: 600;
    color: #333;
    font-size: 1.8em;
    text-underline: none;
}

.content-links:hover {
    color: #ff6935;
}

/* CustomImage.css */
.share-image-container {
    width: 50%;
    overflow: hidden;
    position: relative;
    border-radius: 4px;
}

.share-button-top-2 {
    transition: transform 0.3s ease-in-out;
    background-size: 100%;
    color: #ecf0f1;
    border-radius: 5em 0 0 5em;
    background-image: -moz-linear-gradient(#ff6935, #ff6935);
    background-image: -webkit-linear-gradient(#ff6935, #ff6935);
    background-image: linear-gradient(#ff6935, #ff6935);
    position: absolute;
    bottom: 13em;
    right: 5em;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
}

.share-button-unshadowed {
    background-size: 100%;
    color: #ecf0f1;
    border-radius: 5em 0 0 5em;
    background-image: -moz-linear-gradient(#ff6935, #ff6935);
    background-image: -webkit-linear-gradient(#ff6935, #ff6935);
    background-image: linear-gradient(#ff6935, #ff6935);
    position: absolute;
    bottom: 13em;
    right: 5em;
    transition: all 750ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
}

.share-button:hover {
    background-image: -moz-linear-gradient(#ff4200, #ff4200);
    background-image: -webkit-linear-gradient(#ff4200, #ff4200);
    background-image: linear-gradient(#ff4200, #ff4200);
    transform: scale(1.1);
    transition: transform 0.1s ease-out;
}

.share-button-2:hover {
    background-image: -moz-linear-gradient(#ff4200, #ff4200);
    background-image: -webkit-linear-gradient(#ff4200, #ff4200);
    background-image: linear-gradient(#ff4200, #ff4200);
    border-radius: 1em;
    transform: scale(1.1);
    transition: transform 0.1s ease-out;
}

.store-button {
    background-size: 100%;
    color: #ecf0f1;
    background-image: -moz-linear-gradient(#ff6935, #ff6935);
    background-image: -webkit-linear-gradient(#ff6935, #ff6935);
    background-image: linear-gradient(#ff6935, #ff6935);
    transition: all 750ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.store-button:hover {
    background-image: -moz-linear-gradient(#ff4200, #ff4200);
    background-image: -webkit-linear-gradient(#ff4200, #ff4200);
    background-image: linear-gradient(#ff4200, #ff4200);
    transform: scale(1.02);
    transition: transform 0.1s ease-out;
}

hr.style13 {
    height: 10px;
    border: 0;
    box-shadow: 0 10px 10px -10px #8c8b8b inset;
    border-radius: 1em;
}

.copy-link {
    display:flex;
    flex-direction:row;
    justify-content:center;
    margin-top: 1em;
    box-shadow: 1em 1em 1em -1em hsla(0,0%,0%,.2);
    border-radius: 1em 1em 1em 1em/1em 1em 1em 1em;
    border:solid 0.2em #333333;
    cursor: pointer;
}

.copy-link:hover {
    border:solid 0.2em #ff6935;
}

.lined.thick{
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:100%;
    min-height:50%;
    align-self:center;
    background:transparent;
    padding:0.2em 0.2em;
    margin:0.2em 0.2em;
    transition:all .5s ease;
    outline:none;
    box-shadow: 1em 1em 1em -1em hsla(0,0%,0%,.2);
    border-radius: 1em 1em 1em 1em/1em 1em 1em 1em;
    border:solid 0.2em #333333;
    cursor: pointer;
}

.lined.thick:hover{
    border:solid 0.2em #ff6935;
}

.centered {
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-left: 1em;
    margin-right: 1em;
}

.menu-burguer {
    width: 56px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    z-index: 102;
    transform: scale(0.5);
}

.menu-line {
    height: 6px;
    background-color: #fff;
    border-radius: 3px;
    transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.menu-line:first-child {
    animation: firstLinetoClose 240ms cubic-bezier(0.23, 0.95, 0.58, 1.37);
}

.open .menu-line:first-child {
    animation: firstLinetoOpen 240ms cubic-bezier(0.23, 0.95, 0.58, 1.37);
    animation-fill-mode: forwards;
}

@keyframes firstLinetoOpen {
    0%   {
        transform: translateY(0) rotate(0);
    }

    50% {
        transform: translateY(12px) rotate(0);
    }

    100% {
        transform: translateY(17px) rotate(45deg);
    }
}

@keyframes firstLinetoClose {
    0%   {
        transform: translateY(17px) rotate(0);
    }

    100% {
        transform: translateY(0);
    }
}

.open .menu-line:nth-child(2){
    opacity: 0;
}

.menu-line:last-child {
    animation: lastLinetoClose 240ms cubic-bezier(0.23, 0.95, 0.58, 1.37);
}

.open .menu-line:last-child {
    animation: lastLinetoOpen 240ms cubic-bezier(0.23, 0.95, 0.58, 1.37);
    animation-fill-mode: forwards;
}

@keyframes lastLinetoOpen {
    0%   {
        transform: translateY(0) rotate(0);
    }

    50% {
        transform: translateY(-12px) rotate(0);
    }

    100% {
        transform: translateY(-17px) rotate(-45deg);
    }
}

@keyframes lastLinetoClose {
    0%   {
        transform: translateY(-17px);
    }

    100% {
        transform: translateY(0);
    }
}

:root {
    --orange: #DF6020;
    --smooth-grey: #939F9F;
    --grey: #333333;
    --white: #FFFFF0;
}

/* Cookie Dialog */
#gdpr-cookie-message {
    position: fixed;
    right: 30px;
    bottom: 30px;
    max-width: 550px;
    background-color: var(--grey);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
    margin-left: 30px;
    font-family: system-ui;
    z-index: 1000;
}

#gdpr-cookie-message h4 {
    color: var(--white);
    font-family: monospace, sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

#gdpr-cookie-message p {
    color: var(--white);
    font-size: 15px;
    line-height: 1.5em;
}

#gdpr-cookie-message p:last-child {
    margin-bottom: 0;
    text-align: right;
}

#gdpr-cookie-message a {
    color: var(--white);
    text-decoration: none;
    font-size: 15px;
    padding-bottom: 2px;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.75);
    transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

#gdpr-cookie-message a:hover {
    text-decoration: none;
    padding: 0.3em;
    color: var(--white);
    border-radius: 0.5em;
    border-bottom-color: var(--orange);
    background-color: var(--orange);
    transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.primary-button button {
    border: none;
    background: var(--white);
    color: var(--grey);
    font-family: monospace, sans-serif;
    font-size: 15px;
    padding: 7px;
    border-radius: 3px;
    margin-left: 15px;
    cursor: pointer;
    transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.primary-button button:hover {
    background: #4e724c;
    color: var(--white);
    transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transform: scale(1.1);
}

.other-buttons button {
    border: none;
    background: var(--smooth-grey);
    color: var(--grey);
    font-family: monospace, sans-serif;
    font-size: 15px;
    padding: 7px;
    border-radius: 3px;
    margin-left: 15px;
    cursor: pointer;
    transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.other-buttons button:hover {
    background: var(--orange);
    color: var(--white);
    transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transform: scale(1.1);
}

.button-up {
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    background: transparent;
    cursor: pointer;
    color: #FFFFE0;
}

.button-down {
    position: relative;
    padding: 5px;
    margin: 15px auto;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    transition: all 0.2s ease;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #FFFFE0;
}

.button-up:hover {
    transform: translate3d(0, -10px, 0);
}

.bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    60% {
        -moz-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}
@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    60% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    60% {
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

.mini-bounce {
    -moz-animation: minibounce 2s infinite;
    -webkit-animation: minibounce 2s infinite;
    animation: minibounce 2s infinite;
}

@-moz-keyframes minibounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-2px);
        transform: translateY(-2px);
    }
    60% {
        -moz-transform: translateY(-1px);
        transform: translateY(-1px);
    }
}
@-webkit-keyframes minibounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
    }
    60% {
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
    }
}
@keyframes minibounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-2px);
        -ms-transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
    }
    60% {
        -moz-transform: translateY(-1px);
        -ms-transform: translateY(-1px);
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
    }
}

.animated-shadow {
    background-image:
            linear-gradient(
                    45deg,
                    transparent 45%,
                    hsla(48,20%,90%,1) 45%,
                    hsla(48,20%,90%,1) 55%,
                    transparent 0
            );
    background-size: .05em .05em;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: shad-anim 40s linear infinite;
}

@keyframes shad-anim {
    0% {background-position: 0 0}
    0% {background-position: 100% -100%}
}

.header-fade {
    box-shadow: rgba(0, 0, 0, 0.05) 0 40px 36px -26px inset;
    transform: translateY(-2px);
    border-radius: 1em;
    text-rendering: optimizeLegibility;
    padding-left: 1em;
    padding-right: 1em;
}

.stroke-shadow {
    color: tomato;
    --color1: orangered;
    --color2: #ff704d;
    --color3: #333;
    --interval: 3s;
    display: block;
    text-shadow:
            0 0 1px var(--color1),
            0 0 1px var(--color2),
            0 0 1px var(--color3);
    will-change: filter, color;
    filter: saturate(60%);
    animation: flicker steps(100) var(--interval) 1s infinite;
}

@keyframes flicker {
    50% {
        color: white;
        filter: saturate(200%) hue-rotate(20deg);
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
